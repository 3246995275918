<template>
  <div class="card-text mt-4">
    <div class="row">
      <div class="col">
        <h2>{{ t("stage.stage") }}</h2>
        <dl>
          <template v-if="stage.name !== null">
            <dt>{{ t("general.name") }}</dt>
            <dd>{{ stage.name }}</dd>
          </template>
          <template v-if="stage.capacity_seated !== null">
            <dt>{{ t("stage.capacity_seated") }}</dt>
            <dd>
              {{ stage.capacity_seated }}
            </dd>
          </template>
          <template v-if="stage.capacity_standing !== null">
            <dt>{{ t("stage.capacity_standing") }}</dt>
            <dd>
              {{ stage.capacity_standing }}
            </dd>
          </template>
          <template v-if="stage.width !== null">
            <dt>{{ t("stage.width") }}</dt>
            <dd>
              {{ stage.width }}
            </dd>
          </template>
          <template v-if="stage.height !== null">
            <dt>{{ t("stage.height") }}</dt>
            <dd>
              {{ stage.height }}
            </dd>
          </template>
          <template v-if="stage.depth !== null">
            <dt>{{ t("stage.depth") }}</dt>
            <dd>
              {{ stage.depth }}
            </dd>
          </template>
          <template v-if="stage.clear_height !== null">
            <dt>{{ t("stage.clear_height") }}</dt>
            <dd>
              {{ stage.clear_height }}
            </dd>
          </template>
          <template v-if="stage.electricity !== null">
            <dt>{{ t("stage.electricity") }}</dt>
            <dd>
              {{ stage.electricity }}
            </dd>
          </template>
          <template v-if="stage.distance_to_load !== null">
            <dt>{{ t("stage.distance_to_load") }}</dt>
            <dd>
              {{ stage.distance_to_load }}
            </dd>
          </template>
        </dl>
      </div>
      <div class="col">
        <template v-if="stage.comment !== null">
          <dt>{{ t("general.comment") }}</dt>
          <dd>{{ stage.comment }}</dd>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 pt-2">
        <slot name="cardaction">
          <router-link
            v-if="stage.id"
            :to="'/addresses/stages/' + stage.venue_id"
            class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
            :title="t('general.edit')"
            target="_blank"
          >
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
            </svg>
            {{ t("general.edit") }}
          </router-link>
          <router-link
            :to="'/stages/edit/' + stage.id"
            class="btn btn-warning btn-icon me-2 is-rows-el edit-btn"
            :title="t('general.change_assignment')"
          >
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.change_assignment") }}
          </router-link>
          <router-link
            :to="'/stages/edit/' + stage.id"
            class="btn btn-danger btn-icon me-2 is-rows-el edit-btn"
            :title="t('general.remove')"
          >
            <svg class="bi" width="20" height="20" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
            </svg>
            {{ t("general.remove") }}
          </router-link>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n/index";
export default {
  props: {
    stage: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
