<template>
  <Modal v-if="searchActive" @close="close" :modalActive="searchActive" modalCssClass="modal-fullscreen">
    <template v-slot:header>{{ modalTitle }}</template>
    <template v-slot:body>
      <div class="gws_control_bar d-lg-flex justify-content-between align-items-center gap-2 p-2 mb-2">
        <div class="d-lg-flex align-items-center gap-2">
          <!-- search input -->
          <div class="form-floating mb-2 mb-lg-0">
            <input
              type="text"
              class="form-control"
              id="searchName"
              :placeholder="t('search.name')"
              v-model="searchName"
              @input="search"
            />
            <label for="search">{{ t("search." + selectedSearchType) }}</label>
          </div>
          <!-- search type -->
          <div class="form-floating mb-2 mb-lg-0">
            <select
              class="form-select"
              id="search_type"
              aria-label="Select Search Type"
              v-model="selectedSearchType"
              @change="search"
            >
              <option v-for="searchType in searchTypes" :key="searchType.type" :value="searchType.type">
                {{ searchType.name }}
              </option>
            </select>
            <label for="search_type">{{ t("general.searchtype") }}</label>
          </div>
          <!-- categories -->
          <!-- hier: {{ selectedCategoryId }} -->
          <!-- <div v-if="categories.length > 0" class="form-floating mb-2 mb-lg-0">
            <select
              class="form-select"
              id="serach_categories"
              aria-label="Select Categorie"
              v-model="selectedCategoryId"
              @change="search"
            >
              <option value=""></option>
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.category }}
              </option>
            </select>
            <label for="serach_categories">{{ t("general.categories") }}</label>
          </div> -->
          <!-- company -->
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="search_company"
              v-model="searchCompany"
              @change="search"
            />
            <label class="form-check-label" for="search_company">{{ t("general.company") }}</label>
          </div>
          <!-- person -->
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="search_person"
              v-model="searchPerson"
              @change="search"
            />
            <label class="form-check-label" for="search_person">{{ t("general.person") }}</label>
          </div>
          <!-- number of search results -->
          <div class="border border-light rounded p-1 mt-2 mt-lg-0">
            <small>{{ addresses.length }} {{ t("general.hits") }}</small>
          </div>
          <!-- reset -->
          <div>
            <button
              @click="resetSearch"
              type="button"
              class="btn btn-danger d-block p-0 mt-2 mt-lg-0"
              :title="t('general.reset')"
            >
              <svg class="bi" width="32" height="32" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
              </svg>
            </button>
          </div>
          <!-- loader -->
          <div v-if="searchLoader" class="spinner-border text-secondary global-loader" role="status"></div>
        </div>
        <!-- new -->
        <router-link
          to="/addresses/create"
          class="d-inline-block btn btn-success p-0 mt-2 mt-lg-0"
          :title="t('general.new')"
        >
          <svg class="bi" width="32" height="32" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
          </svg>
        </router-link>
      </div>
      <!-- SEARCH RESULTS -->
      <div class="gws_address_search">
        <div class="card-group gws_card-group" v-if="addresses.length > 0">
          <div class="card gws_search_results mb-sm-2 text-dark me-2" v-for="address in addresses" :key="address.id">
            <!-- SLOT linkaction -->
            <slot name="linkaction" :address="address"></slot>
            <div class="card-body">
              <div class="card-text">
                <dl>
                  <template v-if="address.name !== null">
                    <dt>{{ t("address.name") }}</dt>
                    <dd>{{ address.name }}</dd>
                  </template>
                  <template v-if="address.surname !== null">
                    <dt>{{ t("address.surname") }}</dt>
                    <dd>{{ address.salutation }} {{ address.forename }} {{ address.surname }}</dd>
                  </template>
                </dl>
                <div class="d-flex mb-2">
                  <span v-if="address.is_company == 1" class="badge rounded-pill bg-secondary me-1">{{
                    t("general.company")
                  }}</span>
                  <span v-if="address.is_person == 1" class="badge rounded-pill bg-secondary me-1">{{
                    t("general.person")
                  }}</span>
                </div>
                <div class="d-flex flex-wrap">
                  <span
                    v-for="category in address.categories"
                    :key="category.id"
                    class="badge rounded-pill bg-primary me-1 mb-1"
                    >{{ category.category }}</span
                  >
                </div>
                <!-- SLOT searchaction -->
                <slot name="searchaction" :address="address"></slot>
              </div>
            </div>
            <!-- <div class="card-footer bg-transparent border-success">Footer</div> -->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button @click="close" type="button" class="btn btn-primary">{{ t("general.cancel") }}</button>
    </template>
  </Modal>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import storeConfig from "../../store/config";
import axios from "axios";
import { useI18n } from "vue-i18n/index";
// import { useApiQuery } from "@/composables/useApiQueries";
import { phone, mobile, fax, website } from "@/composables/address.js";
import Modal from "@/components/Modal.vue";
export default {
  components: { Modal },
  props: {
    searchActive: {
      type: Boolean,
      default: false,
    },
    selectedCategoryId: {
      type: String,
      default: "",
    },
    modalTitle: {
      type: String,
      default: "Title",
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const searchName = ref("");
    const searchCompany = ref(false);
    const searchPerson = ref(false);
    const addresses = ref([]);
    const searchLoader = ref(false);
    // const {
    //   result: categories,
    //   query: getCategories,
    //   error: categoriesError,
    // } = useApiQuery({ url: "category", defaultVal: [] });
    // const selectedCategoryId = ref("");
    const searchTypes = ref([
      {
        type: "name",
        name: t("search.name"),
      },
      {
        type: "surname",
        name: t("search.surname"),
      },
      {
        type: "both",
        name: t("search.both"),
      },
    ]);
    const selectedSearchType = ref("name");
    async function search() {
      if (searchName.value.length > 1) {
        try {
          searchLoader.value = true;
          let params = { name: searchName.value };
          if (props.selectedCategoryId.length > 0) {
            params.categoryid = props.selectedCategoryId;
          }
          if (searchPerson.value) {
            params.person = 1;
          }
          if (searchCompany.value) {
            params.company = 1;
          }
          if (selectedSearchType.value) {
            params.searchtype = selectedSearchType.value;
          }
          let result = await axios.get(storeConfig.apiBaseUrl + "search/addresses", {
            params: params,
          });
          addresses.value = result.data;
        } catch (err) {
          if (err.response && err.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
        } finally {
          searchLoader.value = false;
        }
      } else {
        addresses.value = [];
      }
    }

    // function setCategory(id) {
    //   selectedCategoryId.value = id;
    //   search();
    // }

    async function resetSearch() {
      searchName.value = "";
      searchCompany.value = false;
      searchPerson.value = false;
      // selectedCategoryId.value = "";
      selectedSearchType.value = "name";
      await store.dispatch("setCurrentSearchName", "");
      await store.dispatch("setCurrentSearchCompany", false);
      await store.dispatch("setCurrentSearchPerson", false);
      await store.dispatch("setCurrentCategoryId", 0);
      await store.dispatch("setSelectedSearchType", "name");
      addresses.value = [];
    }

    // async function laodCategories() {
    //   await getCategories();
    //   if (categoriesError) {
    //     if (categoriesError.response && categoriesError.response.status == 401) {
    //       store.dispatch("logout");
    //       router.push({ name: "Login" });
    //     }
    //   }
    // }

    // laodCategories();

    const close = () => {
      emit("close");
    };
    return {
      close,
      t,
      search,
      searchName,
      searchPerson,
      searchCompany,
      searchTypes,
      selectedSearchType,
      addresses,
      searchLoader,
      phone,
      mobile,
      fax,
      website,
      // categories,
      // selectedCategoryId,
      // setCategory,
      resetSearch,
    };
  },
};
</script>
