<template>
  <div class="card-text">
    <div class="row">
      <div class="col-lg">
        <dl>
          <template v-if="address.name !== null">
            <dt>{{ t("address.name") }}</dt>
            <dd>{{ address.name }}</dd>
          </template>
          <template v-if="address.surname !== null">
            <dt>{{ t("address.surname") }}</dt>
            <dd>{{ address.salutation }} {{ address.forename }} {{ address.surname }}</dd>
          </template>
          <template
            v-if="
              address.street !== null || address.plz !== null || address.city !== null || address.country_id !== null
            "
          >
            <dt>{{ t("address.address") }}</dt>
            <dd>
              {{ address.street }}<br />
              {{ address.plz }} {{ address.city }}<br />
              {{ countryName(address.country_id) }}
              <!-- <pre>{{ countries }}</pre> -->
            </dd>
          </template>
          <template v-if="address.postcode_pobox !== null">
            <dt>{{ t("address.postbox") }}</dt>
            <dd>
              {{ address.postcode_pobox }}
            </dd>
          </template>
          <template v-if="address.email !== null">
            <dt>{{ t("address.email") }}</dt>
            <dd>
              <a :href="'mailto:' + address.email">{{ address.email }}</a>
            </dd>
          </template>
          <template v-if="address.tel !== null">
            <dt>{{ t("address.tel") }}</dt>
            <dd>
              <a :href="'tel:' + phone(address)">{{ phone(address) }}</a>
            </dd>
          </template>
          <template v-if="address.mobile !== null">
            <dt>{{ t("address.mobile") }}</dt>
            <dd>
              <a :href="'tel:' + mobile(address)">{{ mobile(address) }}</a>
            </dd>
          </template>
          <template v-if="address.fax !== null">
            <dt>{{ t("address.fax") }}</dt>
            <dd>
              {{ fax(address) }}
            </dd>
          </template>
          <template v-if="address.website !== null">
            <dt>{{ t("address.website") }}</dt>
            <dd>
              <a :href="website(address.website)" target="_blank" rel="noopener noreferrer">{{
                website(address.website)
              }}</a>
            </dd>
          </template>
          <template v-if="address.description !== null">
            <dt>{{ t("address.description") }}</dt>
            <dd>
              {{ address.description }}
            </dd>
          </template>
          <!-- valuation -->
          <template v-if="address.valuation !== null">
            <dt>
              {{ t("address.valuation") }}
            </dt>
            <dd>
              <span v-for="n in 7" :key="'valuation_' + address.id + '_' + n" class="text-warning">
                <svg class="bi mx-1" width="20" height="20" fill="currentColor">
                  <use v-if="n <= address.valuation" xlink:href="/assets/img/bootstrap-icons.svg#star-fill"></use>
                  <use v-else xlink:href="/assets/img/bootstrap-icons.svg#star"></use>
                </svg>
              </span>
            </dd>
          </template>
        </dl>
        <!-- company & person -->
        <div class="d-flex mb-2">
          <span v-if="address.is_company == 1" class="badge rounded-pill bg-secondary me-1">
            {{ t("general.company") }}
          </span>
          <span v-if="address.is_person == 1" class="badge rounded-pill bg-secondary me-1">
            {{ t("general.person") }}
          </span>
        </div>
        <div v-if="address.categories" class="d-flex flex-wrap">
          <span
            v-for="category in address.categories"
            :key="category.id"
            :data-id="category.id"
            @click="setCategory(category.id)"
            class="badge rounded-pill bg-primary me-1 mb-1 cursor-pointer"
            >{{ category.category }}</span
          >
        </div>
      </div>
      <div class="col-lg">
        <template v-if="address.comment !== null">
          <dt>{{ t("address.comment") }}</dt>
          <dd class="comment" v-html="comment"></dd>
        </template>
      </div>
      <div v-if="address.lat" class="col-lg gws_map_col">
        <l-map v-model:zoom="map_zoom" :center="[address.lat, address.lon]">
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          ></l-tile-layer>
          <l-control-layers />
          <l-marker :lat-lng="[address.lat, address.lon]">
            <l-popup>{{ address.name }}</l-popup>
          </l-marker>
        </l-map>
      </div>
    </div>
    <div class="row">
      <div class="col-12 border-top mt-1 pt-2">
        <slot name="cardaction"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { phone, mobile, fax, website } from "@/composables/address.js";
import { LMap, LTileLayer, LMarker, LControlLayers, LPopup } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
export default {
  props: {
    address: {
      type: Object,
      default: () => {},
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    LPopup,
  },
  setup(props) {
    const { t } = useI18n();
    const map_zoom = ref(16);
    return {
      t,
      phone,
      mobile,
      fax,
      website,
      countryName(countryId) {
        return props.countries.find((country) => country.id === countryId).name;
      },
      map_zoom,
      comment: computed(() => {
        if (props.address.comment) {
          // return props.address.comment.replace(/\n/g, "<br>");
          // let tmp = document.createElement("DIV");
          // tmp.innerHTML = props.address.comment;
          // return tmp.textContent.replace(/(?:\r\n|\r|\n)/g, "<br>");
          // @see: https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
          const doc = new DOMParser().parseFromString(props.address.comment, "text/html");
          return doc.body.textContent.replace(/(?:\r\n|\r|\n)/g, "<br>") || "";
        }
        return "";
      }),
    };
  },
};
</script>
